.pagination{
    margin: 2rem 0;
    width: 100%;
    @include flex();
    @include justify(flex-end);
    @include align-items(center);
    .page-item{
        .link{
            font-size: .8rem;
            width: 1.75rem;
            height: 1.75rem;
            border: 1px solid $grey4;
            line-height: 1.75rem;
            text-align: center;
            color: $grey4;
            margin-right: .5rem;
            background: transparent;
            padding: 0;
            @include transition(all .25s);
            @include border(.25rem);
            &:hover{
                color: $grey3;
                border: 1px solid $grey3;
            }
            &.active{
                color: $grey3;
                border: 1px solid $grey3;
            }
        }
    }
}