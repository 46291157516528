/* EDAIRY CARDS */

.edairy-card{
    margin: 40px auto;
    width: 90%;
    max-width: 575px;
    padding: 30px;
    background: white;
    @include border(4px);
    @include shadow($shadow-1);
}

