/* MIXINS */

@mixin transform($param){
	transform: $param;
	-webkit-transform: $param;
	-moz-transform: $param;
	-ms-transform: $param;
	-o-transform: $param;
	-khtml-transform: $param;
}

@mixin border($param){
	border-radius: $param;
	-webkit-border-radius: $param;
	-moz-border-radius: $param;
	-ms-border-radius: $param;
	-o-border-radius: $param;
	-khtml-border-radius: $param;
}

@mixin transition($param...){
	transition: $param;
	-webkit-transition: $param;
	-moz-transition: $param;
	-ms-transition: $param;
	-o-transition: $param;
	-khtml-transition: $param;
}

@mixin shadow($param){
	box-shadow: $param;
	-webkit-box-shadow: $param;
	-moz-box-shadow: $param;
	-ms-box-shadow: $param;
	-o-box-shadow: $param;
	-khtml-box-shadow: $param;
}

@mixin filter($filter-type,$filter-amount) { 
	-webkit-filter: $filter-type+unquote('(#{$filter-amount})');
	-moz-filter: $filter-type+unquote('(#{$filter-amount})');
	-ms-filter: $filter-type+unquote('(#{$filter-amount})');
	-o-filter: $filter-type+unquote('(#{$filter-amount})');
	filter: $filter-type+unquote('(#{$filter-amount})');
}

@mixin flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin justify($param) {
  -webkit-justify-content: $param;
     -moz-justify-content: $param;
      -ms-justify-content: $param;
          justify-content: $param;
            -ms-flex-pack: $param;
}

@mixin align-content($param) {
  -webkit-align-content: $param;
     -moz-align-content: $param;
      -ms-align-content: $param;
          align-content: $param;
}

@mixin align-items($param) {
  -webkit-align-items: $param;
     -moz-align-items: $param;
      -ms-align-items: $param;
          align-items: $param;
}

@mixin direction($param) {
  -webkit-flex-direction: $param;
     -moz-flex-direction: $param;
      -ms-flex-direction: $param;
          flex-direction: $param;
}

@mixin calc($property, $param) {
#{$property}: -moz-calc(#{$param});
#{$property}: -webkit-calc(#{$param});
#{$property}: calc(#{$param});
}

@mixin gradient($direction, $list) { 
	background: -webkit-linear-gradient($direction, $list);
	background: -moz-linear-gradient($direction, $list);
	background: -o-linear-gradient($direction, $list);
	background: linear-gradient($direction, $list);
}



@mixin keyframe ($animation_name) {
    @-webkit-keyframes #{$animation_name} {
        @content;
    }

    @-moz-keyframes #{$animation_name} {
        @content;
    }

    @-o-keyframes #{$animation_name} {
        @content;
    }

    @keyframes #{$animation_name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  animation: #{$str};
}

@keyframes gradientLogin {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@mixin responsive($media) {
	@if $media == desktop {
		@media only screen and (min-width: $desktop) { @content; }
	}
	@else if $media == notebook {
		@media only screen and (min-width: $notebook){ @content; }
	}
	@else if $media == landscape-tablet {
		@media only screen and (min-width: $landscape-tablet) and (orientation: landscape) { @content; }
	}
	@else if $media == tablet {
		@media only screen and (min-width: $tablet){ @content; }
	}
	@else if $media == landscape-phone {
		@media only screen and (min-width: $landscape-phone) and (orientation: landscape){ @content; }
	}
	@else if $media == phone {
		@media only screen and (min-width: $portrait-phone) and (max-width:767px){ @content; }
	}
	@else if $media == special-phone {
		@media only screen and (max-width: 767px) and (min-width: 400px), (max-width: 850px) and (min-width: 800px){ @content; }
	}
}

@mixin responsive-min-max($media){
	@if $media == desktop {
		@media only screen and (min-width: $desktop) and (max-width:1919px){ @content; }
	}@else if $media == notebook {
		@media only screen and (min-width: $notebook) and (max-width:1599px){ @content; }
	}
	@else if $media == notebook-large {
		@media only screen and (min-width: $notebook-large) and (max-width:1599px) { @content; }
	}
	@else if $media == landscape-tablet { 
		@media only screen and (min-width: $landscape-tablet) and (orientation: landscape) and (max-width:1299px) { @content; }
	}
	@else if $media == tablet {
		@media only screen and (min-width: $tablet) and (max-width:1023px){ @content; }
	}
	@else if $media == phone {
		@media only screen and (min-width: $portrait-phone) and (max-width:767px){ @content; }
	}
	@else if $media == landscape-phone {
		@media only screen and (min-width: $landscape-phone) and (orientation: landscape) and (max-width:767px){ @content; }
	}
	@else if $media == mobile-dev{
		@media only screen and (min-width: $portrait-phone) and (max-width:1299px){ @content; }
	}
}