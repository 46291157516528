/* HELPERS */
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
body{
    font-family: 'Inter', sans-serif !important;
    // &.modal-open{
    //     overflow: hidden;
    // }
}

*{
    &:focus{
        outline: none!important;
    }
}
.main{
    padding: 60px 0 0;
    display: block;
    width: 100%;
    float: left;
    margin-top: 25px;
}

.light-blue-background{
    background-color: #ffffff;
}
.blue-background{
    background: url('../../assets/img/home/edairy3.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media screen and (max-width: 1400px) {
    .blue-background{
        background: url('../../assets/img/home/edairy3.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.angular-editor-toolbar{
    background-color: $grey5!important;
}

b, strong {
    font-weight: 700!important;
}

.admin-card{
    margin: 40px auto;
    width: 100%;
    max-width: 600px;
    padding: 30px;
    background: white;
    @include shadow($shadow-1);
    @include border(.25rem);
}

/* Chrome/Safari and web-kit-based browsers 
   (if it doesn't work, try maybe also on the parent/wrapper)
*/
::-webkit-file-upload-button {
    cursor:pointer;
}

/* IE11 (if it doesn't work, try maybe also on the parent/wrapper) */
input[type=file] {
    cursor:pointer;
}

/* HOVER Y FOCUS */

a{
    &:hover, &:focus{    
        text-decoration: none;
    }
}

/* ERROR MESAGGE */

.error-msg{
    background: $blue;
    color: white;
    padding: .25rem 1rem;
    text-align: center;
    @include border(3px);
}

.warning-msg{
    background: $red;
    color: white;
    padding: .25rem 1rem;
    text-align: center;
    @include border(3px);
}

/* SELECT */

::-moz-selection{
    color:white;
    background:$blue;
}
::selection{
    color:white;
    background:$blue;
}

/* LOADER */

.loader{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: white;
    .spinner-border{
        position: absolute;
        @include calc(left, '50% - 22.5px');
        @include calc(top, '50% - 22.5px');
        color: $blue;
    }
}

.btn-market-blue {
  border: none;
  background-color: #263d8c;
  color: #fff;
  padding: 12px 15px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 22px;
}