/* VARIABLES */

$blue: #263D8C;
$blue2:#013090;
$blue3:#00A8F6;
$light-blue: #4188C8;
$light-blue-2: #EFF2F9;
$light-blue-3: #D9E0EF;
$light-blue-4: rgba(19, 152, 246, 0.1);
$light-blue-5:#078DF3;
$grey: #656565;
$grey2: #6E6E6E;
$grey3: #A1A1A1;
$grey4: #CCD5EA;
$grey5: #F7F7F7;
$grey6: #E6EAF2;
$grey7:#4D4D4D;
$grey8:#333333;
$grey9:#999999;
$yellow: #F7BA13;
$orange: #F99600;
$orange2:#f15a25;
$green: #8ED800;
$red: #DC3545;
$whatsapp: #25D366;
$gradient: #6ED7FF 0%, #0790F6 100%;
$gradient2: #0790F6 0%, #6ED7FF 100%;
$gradient3: #01309300 0%, #00216653 48%, #00133ADB 100%;
$gradient4: rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 50%;
$gradient5: rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 20%;
$gradient-admin-login: $light-blue 0%, $blue 60%;

// FONTS

$redhat: 'Red Hat Text', sans-serif;


// SHADOWS

$shadow-1: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !default;
$shadow-1-5: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !default;
$shadow-2: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !default;
$shadow-3: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19) !default;
$shadow-4: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21) !default;
$shadow-5: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22) !default;
$shadow-white: -40px 0 110px #fff inset, 40px 0 110px #fff inset, 0 40px 110px #fff inset, 0 -40px 110px #fff inset;
$shadow-1-white: 0 2px 5px 0 rgba(255, 255, 255, 0.16), 0 2px 10px 0 rgba(255, 255, 255, 0.12) !default;
$shadow-1-5-white: 0 5px 11px 0 rgba(255, 255, 255, 0.18), 0 4px 15px 0 rgba(255, 255, 255, 0.15) !default;
$shadow-2-white: 0 8px 17px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19) !default;
$shadow-3-white: 0 12px 15px 0 rgba(255, 255, 255, 0.24), 0 17px 50px 0 rgba(255, 255, 255, 0.19) !default;
$shadow-4-white: 0 16px 28px 0 rgba(255, 255, 255, 0.22), 0 25px 55px 0 rgba(255, 255, 255, 0.21) !default;
$shadow-5-white: 0 27px 24px 0 rgba(255, 255, 255, 0.2), 0 40px 77px 0 rgba(255, 255, 255, 0.22) !default;
$shadow-sidebar: 5px 0px 10px -3px rgba(0, 0, 0, 0.12) !default;

/* RESOLUTIONS */
$portrait-phone:320px;
$landscape-phone : 576px;
$tablet : 768px;
$landscape-tablet : 992px;
$notebook : 1300px;
$notebook-large:1440px;
$desktop : 1600px;