@import './assets/scss/mixins';
@import './assets/scss/fonts';
@import './assets/scss/variables';
@import './assets/scss/helpers';
@import './assets/scss/hamburger';
@import './assets/scss/sliders';
@import './assets/scss/cards';
@import './assets/scss/pagination';
@import './assets/scss/_responsive';
@import '~simplebar/dist/simplebar.css';
@import './assets/scss/simplebar.scss';
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

.cursor-pointer{
  cursor: pointer;
}

.cat-nav.nav-item{
  width: 16.66%!important;
  .nav-link{
    text-align: center !important;
    text-transform: uppercase;
    font-weight: 500;
    color: $grey;
    &.active{
      background-color: transparent !important;
      border-bottom: 1px solid white !important;
      span{
        color: $blue !important;
      }
    }
    &:hover{
      background: $grey6;
      span{
        color: initial;
      }
      i{
        color: $blue;
      }
    }
  }
}

@include responsive-min-max(phone){
  .cat-nav.nav-item{
    width: 33%!important;
  }
}

.col-form-label {
  color: #263D8C;
  font-size: 22px;
}

.form-control {
  height: 55px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  font-size: 22px;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.mt-6, .my-6 {
  margin-top: 6rem !important;
}