/* SIMPLEBAR */

.simplebar-track{
    width: 10px;
    right: 5px;
    &:after{
        content: '';
        background: $grey4;
        width: 10px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 2px;
        z-index: 10;
        @include calc(height, '100% - 4px');
        @include border(8px);
    }
    .simplebar-scrollbar {
        width: 10px;
        min-height: 20px;
        z-index: 100;
        &:before{
            background: $light-blue;
            opacity: 1!important;
            left: 0!important;
            right: 0!important;
            @include border(8px);
        }
        &.simplebar-visible{
            &:before{
                opacity: 1!important;
            }
        }
    }
}
