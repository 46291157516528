/* SLIDERS */

/* SLIDER HOME */

.slider-home{
    .owl-item{
        .item{
            display: block;
            width: 100%;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            padding-top: 56.25%;
        }
    }
    .owl-carousel{
        position: relative;
        .owl-dots{
            position: absolute;
            width: 100%;
            bottom: .5rem;
            .owl-dot{
                span{
                    background: white;
                }
                &.active{
                    span{
                        background: $light-blue;
                    }
                }
            }
        }
    }
}

/* SLIDER ACCOUNT TYPE */

#account-type, #change-type, #pricing{
    .mobile{
        display: block;
        .slider-account-type{
            .owl-carousel{
                margin: 1rem;
                @include calc(width, '100% - 2rem');
                @include calc(height, '100vh - 92px');
                .owl-item{
                    padding: 1rem;
                    .item{
                        background: white;
                        text-align: center;
                        @include shadow($shadow-1);
                        @include border(4px);
                        
                        .account-type-title{
                            h5{
                                padding: 15px 0 0px;
                                margin-bottom: 0;
                                color: $light-blue;
                                font-weight: 500;
                                text-align: center;
                                font-size: 18px;
                                text-transform: uppercase;
                            }
                        }
                        .account-type-free{
                            display: block;
                            margin-bottom: 1rem;
                            font-size: 14px;
                            line-height: 30px;
                            font-weight: 700;
                            color: $light-blue;
                            text-transform: uppercase;
                        }
                        .account-type-price{
                            margin-bottom: 1rem;
                            span{
                                font-size: 20px;
                                color: $grey;
                                font-weight: 500;
                                margin-right: .25rem;
                                &.currency{
                                    font-size: 10px;
                                    margin-right: 0;
                                }
                            }
                        }
                        .account-type-features{
                            padding: .5rem;
                            width: 94%;
                            height: 70%;
                            margin: 0 auto;
                            list-style: none;
                            @include flex();
                            @include align-items(flex-start);
                            @include calc(height, '100% - 165px');
                            .features{
                                width: 90%;
                                padding: 15px 0;
                                border: 1px solid $light-blue-2;
                                border-right: 0;
                                border-left: 0;
                                @include flex();
                                @include align-items(center);
                                .description{
                                    display: block;
                                    width: 80%;
                                    text-align: left;
                                }
                                .value{
                                    display: block;
                                    width: 20%;
                                    text-align: center;
                                }
                                .check{
                                    display: block;
                                    width: 20%;
                                    text-align: center;
                                    &.ok{
                                        color: $green;
                                    }
                                    &.not{
                                        color: $red;
                                    }
                                }
                            }
                        }
                        .payment{
                            margin-top: 30px;
                            .payment-btn{
                                background: $yellow;
                                padding: 10px 50px;
                                border-radius: 60px;
                                color: white;
                                text-transform: uppercase;
                                font-weight: 700;
                                font-size: 10px;
                                @include shadow($shadow-1);
                            }
                        }
                    }
                }
                .owl-dots{
                    .owl-dot{
                        span{
                            background: $grey3;
                        }
                        &.active{
                            span{
                                background: $light-blue;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* SLIDER RELATED PRODUCTS */

.slider-related{
    .owl-item{
        .item{
            width: 100%;
            background: white;
            cursor: pointer;
            @include flex();
            @include direction(column);
            @include justify(flex-start);
            @include align-items(center);
            @include shadow(none);
            @include border(4px);
            @include transition(all .25s);
            .top{
                width: 100%;
                padding-top: 100%;
                position: relative;
                min-height: 320px;
                .labels{
                    position: absolute;
                    left: 0;
                    top: 0;
                    padding: 10px;
                    @include flex();
                    @include align-items(center);
                    .label{
                        padding: 5px 10px;
                        color: white;
                        font-size: 13px;
                        line-height: 1em;
                        font-weight: 500;
                        z-index: 5;
                        margin-right: 0.5rem;
                        @include border(4px);
                        &.sale{
                            background: $orange;
                        }
                        &.featured{
                            background: $blue;
                        }
                    }
                }
                .main-image{
                    position: absolute;
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                    top: 50%;
                    left: 50%;
                    @include transform(translate(-50%, -50%));
                }
            }
            .info{
                width: 100%;
                .product-title{
                    padding: 25px 15px;
                    font-size: 22px;
                    font-weight: 500;
                    color: $blue;
                    min-height: 90px;
                    margin-bottom: 0;
                }
                // .price{
                //     position: relative;
                //     width: 100%;
                //     padding: 20px 15px;
                //     color: white;
                //     margin-bottom: 1rem;
                //     @include gradient(270deg,$gradient);
                //     &.with-sale{
                //         padding: 20px 40% 20px 0;
                //     }
                //     .regular-price{
                //         text-align: center;
                //         font-size: 1.8rem;
                //         font-weight: 700;
                //     }
                //     .sale-price{
                //         width: 60%;
                //         padding-left: 15px;
                //         box-sizing: border-box;
                //         font-size: 1.8rem;
                //         font-weight: 700;
                //     }
                //     .price-box{
                //         position: absolute;
                //         right: 0;
                //         top: 0;
                //         width: 40%;
                //         height: 100%;
                //         background: $blue;
                //         padding: 15px;
                //         box-sizing: border-box;
                //         @include flex();
                //         @include direction(column);
                //         @include align-items(flex-start);
                //         .percent{
                //             padding: 5px;
                //             font-size: 1rem;
                //             line-height: 1rem;
                //             color: white;
                //             background: $orange;
                //             margin-bottom: 5px;
                //             @include border(4px);
                //         }
                //         .original-price{
                //             text-decoration: line-through;
                //             font-size: 1rem;
                //             color: white;
                //         }
                //     }
                // }
                .contact-btn{
                    position: relative;
                    width: 100%;
                    color: white;
                    font-weight: 700;
                    text-transform: uppercase;
                    z-index: 3;
                    margin: 0 auto;
                    padding: 0 1rem;
                    line-height: 50px;
                    @include flex();
                    @include justify(center);
                    @include align-items(center);
                    @include gradient(270deg,$gradient);
                    @include shadow(none);
                    @include border(0);
                    @include transition(all .25s);
                    span{
                        color: white;
                        font-weight: 700;
                        text-transform: uppercase;
                        z-index: 8;
                        font-size: 16px;
                        margin-right: 30px;
                    }
                    img{
                        z-index: 8;
                        width: 20px;
                    }
                    &:before{
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        z-index: 5;
                        @include gradient(270deg,$gradient2);
                        @include transition(all .5s);
                    }
                }
                .bottom{
                    padding: 15px;
                    @include flex();
                    @include justify(space-between);
                    .seller-box{
                        padding-bottom: 0;
                        color: $blue;
                        @include flex();
                        @include justify(flex-start);
                        @include align-items(flex-start);
                        @include direction(column);
                        .seller-text{
                            font-size: 0.8rem;
                            margin-right: 1rem;
                        }
                        .seller{
                            @include flex();
                            @include justify(flex-start);
                            @include align-items(center);
                            .seller-name{
                                font-size: 1rem;
                                margin-right: .25rem;
                                color: $light-blue;
                            }
                            .verified-icon{
                                width: 20px;
                            }
                        }
                    }
                    .brand-box{
                        color: $blue;
                        @include flex();
                        @include justify(flex-start);
                        @include align-items(flex-start);
                        @include direction(column);
                        .brand-text{
                            font-size: 0.8rem;
                            margin-right: 1rem;
                        }
                        .brand{
                            font-size: 1rem;
                        }
                    }
                    .bottom-box{
                        padding: 5px 15px;
                        color: $blue;
                        margin: 0.5rem 1rem;
                        @include border(4px);
                        @include flex();
                        @include align-items(center);
                        @include justify(center);
                        span{
                            margin-right: 0.15rem;
                        }
                        i{
                            font-size: 20px;
                            line-height: 1em;
                            margin-right: 1rem;
                        }
                        .number{
                            font-size: 13px;
                            font-weight: 700;
                            line-height: 1em;
                        }
                        .text{
                            font-size: 13px;
                            font-weight: 700;
                            line-height: 1em;
                        }
                        .normal-text{
                            font-size: 13px;
                            line-height: 1em;
                        }
                        &.box-quotes{
                            background: rgba(249,150,0,0.25);
                        }
                        &.box-financing{
                            background: rgba(204,213,234,0.25);
                        }
                    }
                }
            }
        }
    }
    .owl-nav{
        position: absolute;
        width: 100%;
        top: 50%;
        margin-top: 0;
        @include transform(translateY(-50%));
        .owl-prev{
            position: absolute;
            left: -1.75rem;
            margin: 0;
            background: $blue;
            width: 3.5rem;
            height: 3.5rem;
            text-align: center;
            line-height: 3.5rem;
            color: #FFF;
            padding: 0;
            border-radius: 50%;
            @include transform(translateY(-50%));
            // @include shadow($shadow-1);
            @include transition(all .25s);
            i{
                font-size: 1.25rem;
                line-height: 3.5rem;
            }
            &:hover{
                background: $blue;
                color: white;
            }
        }
        .owl-next{
            position: absolute;
            right: -1.75rem;
            margin: 0;
            background: $blue;
            width: 3.5rem;
            height: 3.5rem;
            text-align: center;
            line-height: 3.5rem;
            color: #FFF;
            padding: 0;
            border-radius: 50%;
            @include transform(translateY(-50%));
            // @include shadow($shadow-1);
            @include transition(all .25s);
            i{
                font-size: 1.25rem;
                line-height: 3.5rem;
            }
            &:hover{
                background: $blue;
                color: white;
            }
        }
    }
}

/* SLIDER STORES */

.slider-stores{
    .item{
        width: 100%;
        background: white;
        border: 1px solid $grey4;
        padding: 1rem;
        margin: 0.5rem 0;
        border-radius: 15px;
        @include flex();
        @include direction(column);
        @include align-items(center);
        @include justify(center);
        @include shadow(none);
        @include transition(all .25s);
        min-height: 250px;
        .pic{
            height: 50%;
            width: 100%;
            padding: 0 0 2rem;
            @include flex();
            @include justify(center);
            .picture{
                display: block;
                width: 100px;
                height: 100px;
                background: $grey4;
                @include border(50%);
                .picture-image{
                    display: block;
                    width: 100%;
                    @include border(50%);
                }
            }
        }
        .store-name-box{
            margin-bottom: 4.5rem;
            @include flex();
            @include align-items(center);
            @include justify(center);
            .store-name{
                font-size: 16px;
                color: $grey;
                font-weight: 500;
                position: relative;
                width: 100%;
                text-align: center;
                margin-bottom: 0;
                margin-right: 0.25rem;
                &:after{
                    content: '';
                    position: absolute;
                    bottom: -2.5rem;
                    left: 10%;
                    width: 80%;
                    height: 1px;
                    background: $grey4;
                }
            }
            .verified-icon{
                width: 20px;
            }
        }
        .store-btn{
            display: block;
            background: $blue;
            color: white;
            padding: 0.75rem 2.2rem;
            text-align: center;
            font-weight: 500;
            font-size: 12px;
            border: none;
            text-transform: uppercase;
            @include border(4px);
            @include shadow(none);
            @include transition(all .25s);
            &:hover{
                background: $light-blue;
                @include shadow($shadow-1);
            }
        }
        &:hover{
            @include shadow($shadow-1);
        }
    }
    .owl-nav{
        position: absolute;
        width: 100%;
        top: 50%;
        margin-top: 0;
        @include transform(translateY(-50%));
        .owl-prev{
            position: absolute;
            left: -1.75rem;
            margin: 0;
            background: transparent;
            width: 3.5rem;
            height: 3.5rem;
            text-align: center;
            line-height: 3.5rem;
            color: #fff;
            padding: 0;
            border-radius: 50%;
            @include transform(translateY(-50%));
            // @include shadow($shadow-1);
            @include transition(all .25s);
            i{
                font-size: 1.25rem;
                line-height: 3.5rem;
            }
            &:hover{
                background: transparent;
                color: white;
            }
        }
        .owl-next{
            position: absolute;
            right: -1.75rem;
            margin: 0;
            background: transparent;
            width: 3.5rem;
            height: 3.5rem;
            text-align: center;
            line-height: 3.5rem;
            color: #FFF;
            padding: 0;
            border-radius: 50%;
            @include transform(translateY(-50%));
            // @include shadow($shadow-1);
            @include transition(all .25s);
            i{
                font-size: 1.25rem;
                line-height: 3.5rem;
            }
            &:hover{
                background: transparent;
                color: white;
            }
        }
    }
}

.slider-stores-product{
    .item{
        width: 100%;
        background: white;
        border: 1px solid $grey4;
        padding: 1rem;
        margin: 0.5rem 0;
        border-radius: 15px;
        @include flex();
        @include direction(column);
        @include align-items(center);
        @include justify(center);
        @include shadow(none);
        @include transition(all .25s);
        min-height: 250px;
        .pic{
            height: 50%;
            width: 100%;
            padding: 0 0 2rem;
            @include flex();
            @include justify(center);
            .picture{
                display: block;
                width: 100px;
                height: 100px;
                background: $grey4;
                @include border(50%);
                .picture-image{
                    display: block;
                    width: 100%;
                    @include border(50%);
                }
            }
        }
        .store-name-box{
            margin-bottom: 4.5rem;
            @include flex();
            @include align-items(center);
            @include justify(center);
            .store-name{
                font-size: 16px;
                color: $grey;
                font-weight: 500;
                position: relative;
                width: 100%;
                text-align: center;
                margin-bottom: 0;
                margin-right: 0.25rem;
                &:after{
                    content: '';
                    position: absolute;
                    bottom: -2.5rem;
                    left: 10%;
                    width: 80%;
                    height: 1px;
                    background: $grey4;
                }
            }
            .verified-icon{
                width: 20px;
            }
        }
        .store-btn{
            display: block;
            background: $blue;
            color: white;
            padding: 0.75rem 2.2rem;
            text-align: center;
            font-weight: 500;
            font-size: 12px;
            border: none;
            text-transform: uppercase;
            @include border(4px);
            @include shadow(none);
            @include transition(all .25s);
            &:hover{
                background: $light-blue;
                @include shadow($shadow-1);
            }
        }
        &:hover{
            @include shadow($shadow-1);
        }
    }
    .owl-nav{
        position: absolute;
        width: 100%;
        top: 50%;
        margin-top: 0;
        @include transform(translateY(-50%));
        .owl-prev{
            position: absolute;
            left: -3.75rem;
            margin: 0;
            background: transparent;
            width: 3.5rem;
            height: 3.5rem;
            text-align: center;
            line-height: 3.5rem;
            color: #263D8C;
            padding: 0;
            border-radius: 50%;
            @include transform(translateY(-50%));
            // @include shadow($shadow-1);
            @include transition(all .25s);
            i{
                font-size: 1.25rem;
                line-height: 3.5rem;
            }
            &:hover{
                background: transparent;
                color: #263D8C;
            }
        }
        .owl-next{
            position: absolute;
            right: -3.75rem;
            margin: 0;
            background: transparent;
            width: 3.5rem;
            height: 3.5rem;
            text-align: center;
            line-height: 3.5rem;
            color: #263D8C;
            padding: 0;
            border-radius: 50%;
            @include transform(translateY(-50%));
            // @include shadow($shadow-1);
            @include transition(all .25s);
            i{
                font-size: 1.25rem;
                line-height: 3.5rem;
            }
            &:hover{
                background: transparent;
                color: #263D8C;
            }
        }
    }
}

/* SLIDER CREATE PRODUCT */

.create-product-slider{
    .item{
        border: 1px solid $grey4;
        @include border(6px);
        @include flex();
        @include justify(center);
        @include align-items(center);
        @include direction(column);
        .cat-image{
            width: 110px;
            height: 110px;
            margin: 1rem auto;
        }
        .cat-title{
            width: 70%;
            margin: 0 auto 2rem;
            font-size: 1.25rem;
            line-height: 1.5rem;
            color: $blue;
            font-weight: 700;
            text-align: center;
            text-transform: capitalize;
        }
        .subcategories{
            margin: 0 auto 1rem;
            text-align: center;
            line-height: 1rem;
            min-height: 3rem;
            @include calc(width, '100% - 2rem');
            span{
                font-size: 0.8rem;
                color: $grey;
                &:after{
                    content: '-';
                }
                &:last-child{
                    &:after{
                        content: '';
                    }
                }
            }
        }
    }
}

/* RESPONSIVE */

@include responsive(landscape-phone){

    /* SLIDER ACCOUNT TYPE */

    #account-type, #change-type, #pricing{
        .mobile{
            .slider-account-type{
                .owl-carousel{
                    height: auto;
                    .owl-item{
                        .item{
                            height: auto;
                            padding-bottom: 2rem;
                            .account-type-features{
                                height: 190px;
                            }
                        }
                    }
                }
            }
        }
    }

}

@include responsive(tablet){

    /* SLIDER ACCOUNT TYPE */

    #account-type, #change-type, #pricing{
        .mobile{
            .slider-account-type{
                .owl-carousel{
                    .owl-item{
                        .item{
                            height: auto;
                            padding-bottom: 2rem;
                            .account-type-features{
                                .features{
                                    width: 100%;
                                    .description{
                                        width: 90%;
                                    }
                                    .value{
                                        width: 10%;
                                    }
                                    .check{
                                        width: 10%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

@include responsive(landscape-tablet){

    /* SLIDER HOME */

    .slider-home{
        .owl-item{
            .item{
                padding-top: 0;
                @include calc(height, '100vh - 60px');
            }
        }
    }

    /* SLIDER ACCOUNT TYPE */

    #account-type, #change-type, #pricing{
        .mobile{
            .slider-account-type{
                .owl-carousel{
                    .owl-item{
                        .item{
                            .account-type-features{
                                height: 340px;
                            }
                        }
                    }
                }
            } 
        }
    }

}

@include responsive(notebook){

    /* SLIDER HOME */

    .slider-home{
        .owl-item{
            .item{
                padding-top: 0;
                @include calc(height, '100vh - 420px');
            }
        }
    }

    /* SLIDER RELATED PRODUCTS */

    .slider-related{
        .owl-item{
            padding: 5px;
            .item{
                &:hover{
                    @include shadow($shadow-1);
                }
            }
        }
    }

    /* SLIDER ACCOUNT TYPE */

    #account-type, #change-type, #pricing{
        .mobile{
            display: none;
        }
    }

    /* SLIDER STORES */

    .slider-stores{
        .owl-nav{
            .owl-prev{
                left: -5rem;
            }
            .owl-next{
                right: -5rem;
            }
        }
    }

}

@include responsive(desktop){

    /* SLIDER STORES */

    .slider-stores{
        .owl-nav{
            .owl-prev{
                left: -7rem;
            }
            .owl-next{
                right: -7rem;
            }
        }
    }

}