/* HAMBURGER */

header{
    .animated-icon1{
        width: 25px;
        height: 20px;
        position: relative;
        margin: 0px;
        @include transform(rotate(0deg));
        @include transition(.5s ease-in-out);
        cursor: pointer;
        span{
            display: block;
            background: $blue;
            position: absolute;
            height: 3px;
            width: 100%;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            @include transform(rotate(0deg));
            @include transition(.25s ease-in-out);
            &:nth-child(1){
                top: 0;
            }
            &:nth-child(2){
                top: 9px;
            }
            &:nth-child(3){
                top: 18px;
            }
        }
        &.open{
            span{
                &:nth-child(1){
                    top: 9px;
                    @include transform(rotate(-135deg));
                }
                &:nth-child(2){
                    opacity: 0;
                    left: -30px;
                }
                &:nth-child(3){
                    top: 9px;
                    @include transform(rotate(135deg));
                }
            }
        }
        &:focus{
            outline: none;
        }
    }
}