/* RESPONSIVE */

@include responsive(landscape-phone){
  .main{
    margin-top: 16px;
  }
}

@include responsive(tablet){

  /* HELPERS */

  .main{
    @include calc( min-height, '100vh - 388px');
  }
  .main{
    margin-top: 16px;
  }

}

@include responsive(landscape-tablet){

  /* HELPERS */

  .main{
    @include calc( min-height, '100vh - 259.5px');
  }

  /* EDAIRY CARDS */

  .edairy-card{
    margin: 70px auto;
    padding: 55px;
  }

}

@include responsive(notebook){

  /* HELPERS */

  .main{
    padding: 115px 0 0;
    @include calc( min-height, '100vh - 260.5px');
    &.withSidebar{
/*      float: left;
      width: 75%;*/
      position: relative;
      padding: 115px 0 0;
      overflow-y: auto;
      &::before{
        content: '';
        position: absolute;
        width: 25%;
        height: 100%;
        left: -25%;
        top: 0;
        background: red;
      }
    }
  }

}

@include responsive(desktop){

  /* HELPERS */

  .container{
    max-width: 1700px;
  }

}

@include responsive(special-phone){
  .main{
    margin-top: -5px;
  }

}